import { StyleProvider } from "_Providers/Style/StyleProvider";
import React from "react";
import ReactDOM from "react-dom";
import "./_Services/i18n/i18n";
import { BrowserCheck } from "./BrowserCheck";
import * as serviceWorker from "./serviceWorker";
import { FetchingStateProvider } from "./_Context/FetchingStateContext/FetchingStateContext";

ReactDOM.render(
    <FetchingStateProvider>
        <StyleProvider>
            <BrowserCheck />
        </StyleProvider>
    </FetchingStateProvider>,
    document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
