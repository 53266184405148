import { css, Global } from "@emotion/core";
import styled from "@emotion/styled";
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { ICommonProps } from "_Components/global.types";
import { colors } from "_Styles/colors";
import { defaultFontSize } from "_Styles/fontSizes";
import theme from "_Styles/materialTheme";
import { THEME_ELEMENTS } from "_Styles/styling.types";
import React, { FunctionComponent } from "react";

const globalStyles = css`
    html,
    body,
    #root {
        font-family: Helvetica, monospace;
        color: ${colors[THEME_ELEMENTS.SECONDARY]};
        font-size: 62.5%;
        overscroll-behavior-x: none;
    }
`;

const Body = styled.div`
    font-size: ${defaultFontSize};
`;

export const StyleProvider: FunctionComponent<ICommonProps> = ({
    children,
}: ICommonProps) => {
    return (
        <MuiThemeProvider theme={theme}>
            <Global styles={globalStyles} />
            <CssBaseline />
            <Body>{children}</Body>
        </MuiThemeProvider>
    );
};
