import styled from "@emotion/styled";
import { backgroundColors } from "_Styles/colors";
import { THEME_ELEMENTS } from "_Styles/styling.types";
import React, { Component, ReactNode } from "react";

/* tslint:disable */
const getPaths = (len: number) =>
    [
        <path
            key={1}
            className="cls-1"
            d="M66.2,3.86A49.48,49.48,0,0,0,52.41,1.5h-.1a3.5,3.5,0,0,0-3.49,3.4,3.51,3.51,0,0,0,3.4,3.6,42.23,42.23,0,0,1,11.83,2,3.51,3.51,0,0,0,4.41-2.26,3.5,3.5,0,0,0-2.26-4.4Z"
        />,
        <path
            key={2}
            className="cls-1"
            d="M74.79,15.76a42.71,42.71,0,0,1,8.91,8.07,3.5,3.5,0,0,0,4.92.45,3.5,3.5,0,0,0,.46-4.93A50.06,50.06,0,0,0,78.71,10a3.5,3.5,0,0,0-3.92,5.8Z"
        />,
        <path
            key={3}
            className="cls-1"
            d="M90,34a42.56,42.56,0,0,1,3.18,11.57,3.49,3.49,0,0,0,3.91,3,3.43,3.43,0,0,0,2.33-1.32,3.49,3.49,0,0,0,.7-2.59,48.72,48.72,0,0,0-3.71-13.49,3.46,3.46,0,0,0-1.93-1.86A3.5,3.5,0,0,0,90,34Z"
        />,
        <path
            key={4}
            className="cls-1"
            d="M97,54.58a3.07,3.07,0,0,0-.53,0,3.52,3.52,0,0,0-3.46,3A42.07,42.07,0,0,1,89.51,69a3.44,3.44,0,0,0-.12,2.67,3.47,3.47,0,0,0,1.8,2A3.51,3.51,0,0,0,95.85,72a49,49,0,0,0,4.07-13.39,3.49,3.49,0,0,0-2.93-4Z"
        />,
        <path
            key={5}
            className="cls-1"
            d="M85.58,77.82A3.5,3.5,0,0,0,83,79a42.91,42.91,0,0,1-9.12,7.83,3.5,3.5,0,0,0,3.76,5.9,50,50,0,0,0,10.62-9.11,3.5,3.5,0,0,0-2.63-5.81Z"
        />,
        <path
            key={6}
            className="cls-1"
            d="M63.87,91.66a3.72,3.72,0,0,0-1,.13A42.44,42.44,0,0,1,51,93.48a3.5,3.5,0,0,0,0,7,49.68,49.68,0,0,0,13.85-2,3.5,3.5,0,0,0-1-6.86Z"
        />,
        <path
            key={7}
            className="cls-1"
            d="M36.89,91.08a42.38,42.38,0,0,1-10.65-5.55,3.43,3.43,0,0,0-2.61-.61,3.49,3.49,0,0,0-1.47,6.29,49.18,49.18,0,0,0,12.4,6.47,3.5,3.5,0,0,0,2.33-6.6Z"
        />,

        <path
            key={8}
            className="cls-1"
            d="M11.55,66.83a3.5,3.5,0,0,0-6.49,2.61,49.3,49.3,0,0,0,7,12.11,3.5,3.5,0,0,0,5.51-4.33A42.16,42.16,0,0,1,11.55,66.83Z"
        />,
        <path
            key={9}
            className="cls-1"
            d="M7.93,57.74a3.44,3.44,0,0,0,.77-2.56c-.13-1.4-.2-2.79-.2-4.22a42.89,42.89,0,0,1,.71-7.78,3.49,3.49,0,0,0-5.42-3.52A3.44,3.44,0,0,0,2.33,41.9,49.79,49.79,0,0,0,1.5,51c0,1.65.08,3.3.24,4.91a3.5,3.5,0,0,0,6.19,1.88Z"
        />,
        <path
            key={10}
            className="cls-1"
            d="M8.33,33.42A3.51,3.51,0,0,0,13,31.87,42.76,42.76,0,0,1,19.9,22a3.49,3.49,0,0,0-2.56-5.88h-.12a3.48,3.48,0,0,0-2.44,1.11,49.79,49.79,0,0,0-8,11.48A3.5,3.5,0,0,0,8.33,33.42Z"
        />,
        <path
            key={11}
            className="cls-1"
            d="M38.51,3.07a49.38,49.38,0,0,0-12.9,5.41,3.5,3.5,0,0,0,.94,6.4,3.46,3.46,0,0,0,2.65-.39A42.71,42.71,0,0,1,40.27,9.84a3.49,3.49,0,0,0,2.51-4.26A3.5,3.5,0,0,0,38.51,3.07Z"
        />,
    ].slice(0, len);

/* tslint:enable */

const LOADER_SIZE = 10;
const CONTENT_SIZE = 7;

const Loader = styled.div`
    position: relative;
    width: ${LOADER_SIZE}rem;
    height: ${LOADER_SIZE}rem;
`;

const Content = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: ${(LOADER_SIZE - CONTENT_SIZE) / 2}rem;
    left: ${(LOADER_SIZE - CONTENT_SIZE) / 2}rem;

    width: ${CONTENT_SIZE}rem;
    height: ${CONTENT_SIZE}rem;

    border-radius: ${CONTENT_SIZE / 2}rem;

    background-color: ${backgroundColors[THEME_ELEMENTS.PRIMARY]};
`;

export enum LOADER_STATUS {
    PENDING,
    SUCCESS,
    FAILED,
}

interface IProps {
    status: LOADER_STATUS;
}

interface IState {
    loaderLength: number;
    progressLoaderIntervalId: number | undefined;
}

const LOADER_CONTENT: {
    [status: string]: (loaderLength: number) => ReactNode | string | number;
} = {
    [LOADER_STATUS.FAILED]: () => "X",
    [LOADER_STATUS.SUCCESS]: () => "ok",
    [LOADER_STATUS.PENDING]: (loaderLength: number) =>
        `${Math.round((loaderLength / 11) * 100)}%`,
};

export class LoaderSpinner extends Component<IProps, IState> {
    private static readonly timerValue = 150;

    public state = {
        loaderLength: 1,
        progressLoaderIntervalId: undefined,
    };

    public componentDidMount() {
        const progressLoaderIntervalId = window.setInterval(
            this.updateLoaderLength,
            LoaderSpinner.timerValue,
        );
        this.setState({ progressLoaderIntervalId });
    }

    public componentWillUnmount() {
        clearInterval(this.state.progressLoaderIntervalId);
    }

    private updateLoaderLength = () => {
        const { status } = this.props;
        const { loaderLength } = this.state;

        if (loaderLength >= 11 && status === LOADER_STATUS.PENDING) {
            this.setState({ loaderLength: 0 });
        } else {
            this.setState({ loaderLength: loaderLength + 1 });
        }
    };

    public render() {
        const { status } = this.props;
        const { loaderLength } = this.state;
        return (
            <Loader>
                <svg viewBox="0 0 101.63 101.98">
                    <g fill="#2d73e6">{getPaths(loaderLength)}</g>
                </svg>
                <Content>{LOADER_CONTENT[status](loaderLength)}</Content>
            </Loader>
        );
    }
}
