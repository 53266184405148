import { THEME_ELEMENTS, THEME_FUNCTIONS, THEME_STATES } from "./styling.types";

export const white = "#FFFFFF";
export const blue = "#2665E2";
export const lightBlue = "#edf0fa";
export const transparentBlue = "rgba(77, 145, 231, 0.13)";
export const green = "#66bb6b";
export const red = "#f64747";
export const colorDanger = "#DC5A5A";

export const dataSourceColors = [
    "#66bb6b",
    "#519ae6",
    "#d27ec2",
    "#ebaf32",
    "#61bdc7",
    "#9bb4da",
    "#bb7171",
];
export function randomColor() {
    return "#" + (Math.random().toString(16) + "000000").substring(2, 8);
}

export const grayShades = {
    g100: "rgba(172, 172, 180, 0.1)",
    g150: "rgba(226, 226, 226, 0.29)",
    g170: "rgba(226, 226, 226, 0.5)",
    g200: "#f0f4fa",
    g300: "#e2e2e2",
    g400: "#5d5b5b",
    g500: "#b7bbc5",
    g600: "#d8d8d8",
    g700: "#a4a4a4",
    g800: "#E8EDF4",
    g900: "#212121",
};

export const colors = {
    [THEME_ELEMENTS.PRIMARY]: blue,
    [THEME_ELEMENTS.SECONDARY]: grayShades.g400,
};

export const backgroundColors = {
    [THEME_ELEMENTS.PRIMARY]: white,
    [THEME_ELEMENTS.SECONDARY]: grayShades.g200,
    [THEME_STATES.DISABLED]: grayShades.g100,
    [THEME_STATES.HIGHLIGHTED]: lightBlue,
    [THEME_FUNCTIONS.INFO]: grayShades.g150,
};

export const fontColors = {
    [THEME_ELEMENTS.PRIMARY]: grayShades.g400,
};

export const columnListColors = {
    sectionBackground: "#f4f4f4",
    itemHandleBackground: "#e4e4e4",
};

export const gridColors = {
    headerBackgroundColor: "#fafbfd",
    footerBackgroundColor: "#fafbfd",
};

export const boxShadow = "0px 0px 2px 2px rgba(0,0,0,0.05)";
export const textButtonBoxShadow = "0 1px 2px 2px rgba(77, 145, 231, 0.13)";
export const dragAndDropItemBoxShadow = "0px 0px 12px rgba(0, 0, 0, 0.03)";
export const basketShadow = "0 1px 4px 0 rgba(77, 145, 231, 0.6)";
