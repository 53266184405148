export enum THEME_ELEMENTS {
    PRIMARY = "primary",
    SECONDARY = "secondary",
}

export enum THEME_STATES {
    DISABLED = "disabled",
    HIGHLIGHTED = "highlighted",
}

export enum THEME_FUNCTIONS {
    INFO = "info",
}
