import { SNotSupported } from "_Components/Layout/SNotSupported/SNotSupported";
import { SLoaderCurtain } from "_Components/LoaderCurtain/SLoaderCurtain";
import React, { FunctionComponent, lazy, Suspense } from "react";

const Supported = lazy(() => import("./Supported"));

const supportedBrowsersIdentifiers = [
    "Chrome",
    "Firefox",
    "Opera",
    "OPR",
    "Safari",
];

export const BrowserCheck: FunctionComponent = () => {
    const isSupportedBrowser = supportedBrowsersIdentifiers.some(
        browser => navigator.userAgent.indexOf(browser) !== -1,
    );
    return isSupportedBrowser ? (
        <Suspense fallback={<SLoaderCurtain />}>
            <Supported />
        </Suspense>
    ) : (
        <SNotSupported />
    );
};
