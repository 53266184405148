import styled from "@emotion/styled";
import { blue } from "_Styles/colors";
import React, { FunctionComponent } from "react";
import chrome from "./assets/chrome.svg";
import firefox from "./assets/firefox.svg";
import opera from "./assets/opera.svg";
import safari from "./assets/safari.svg";

export enum BROWSERS {
    CHROME,
    FIREFOX,
    SAFARI,
    OPERA,
}

const StyledListItem = styled.a`
    text-decoration: none;
    display: inline-block;
    &:visited {
        color: ${blue};
    }
`;

const StyledLogo = styled.img`
    width: 9.6rem;
    height: 9.6rem;
    margin: 5rem 4rem 1.5rem 4rem;
`;

const BROWSER_IMG = {
    [BROWSERS.CHROME]: <StyledLogo src={chrome} alt="chrome" />,
    [BROWSERS.FIREFOX]: <StyledLogo src={firefox} alt="firefox" />,
    [BROWSERS.SAFARI]: <StyledLogo src={safari} alt="safari" />,
    [BROWSERS.OPERA]: <StyledLogo src={opera} alt="opera" />,
};

const BROWSER_URL = {
    [BROWSERS.CHROME]: "https://www.google.com/intl/nl/chrome/",
    [BROWSERS.FIREFOX]: "https://www.mozilla.org/pl/firefox/new/",
    [BROWSERS.SAFARI]: "https://www.apple.com/safari/",
    [BROWSERS.OPERA]: "https://www.opera.com/pl/download/",
};

const BROWSER_TITLE = {
    [BROWSERS.CHROME]: "Google Chrome",
    [BROWSERS.FIREFOX]: "Mozilla Firefox",
    [BROWSERS.SAFARI]: "Safari",
    [BROWSERS.OPERA]: "Opera",
};

interface IProps {
    browser: BROWSERS;
}

export const BrowserListItem: FunctionComponent<IProps> = ({
    browser,
}: IProps) => {
    return (
        <StyledListItem href={BROWSER_URL[browser]} target="_blank">
            <div>{BROWSER_IMG[browser]}</div>
            {BROWSER_TITLE[browser]}
        </StyledListItem>
    );
};
