import { SerializedStyles } from "@emotion/serialize";
import React, { FunctionComponent, MouseEvent } from "react";
import { ICONS, PATHS_ICONS } from "./SIconPaths";

export interface ISIconProps {
    type: ICONS;
    viewBox?: string;
    onClick?: ((event?: MouseEvent) => void) | undefined;
    svgSerializedStyle?: SerializedStyles;
    gSerializedStyle?: SerializedStyles;
    pathSerializedStyle?: SerializedStyles;
    className?: string;
}

export const SIcon: FunctionComponent<ISIconProps> = ({
    type,
    viewBox,
    onClick,
    svgSerializedStyle,
    gSerializedStyle,
    pathSerializedStyle,
    className,
}: ISIconProps) => {
    return (
        <svg
            className={className}
            css={svgSerializedStyle}
            viewBox={viewBox}
            onClick={onClick}
        >
            <g css={gSerializedStyle}>
                {PATHS_ICONS[type].map((path, index) => (
                    <path css={pathSerializedStyle} d={path} key={index} />
                ))}
            </g>
        </svg>
    );
};
