import translationsNL from "_Translations/nl/translation.json";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
    nl: {
        translation: translationsNL,
    },
};

export const i18nReact = i18n
    .use(initReactI18next)
    .init({ resources, lng: "nl", fallbackLng: "nl", debug: false });
