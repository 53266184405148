import { createMuiTheme } from "@material-ui/core/styles";
import {
    backgroundColors,
    blue,
    fontColors,
    green,
    red,
    white,
} from "_Styles/colors";
import { THEME_ELEMENTS, THEME_STATES } from "_Styles/styling.types";

export default createMuiTheme({
    palette: {
        common: { white },
        primary: {
            main: blue,
        },
    },
    typography: {
        fontFamily: "Helvetica, monospace",
        htmlFontSize: 10,
        fontSize: 16,
    },
    overrides: {
        MuiTable: {
            root: {
                width: "100%",
            },
        },
        MuiTableRow: {
            root: {
                borderRadius: "4px",
                "&$hover:hover": {
                    backgroundColor: backgroundColors[THEME_STATES.HIGHLIGHTED],
                },
            },
        },
        MuiTableCell: {
            root: {
                color: fontColors[THEME_ELEMENTS.PRIMARY],
                maxWidth: 200,
            },
            head: {
                padding: 10,
                fontSize: 14,
                color: fontColors[THEME_ELEMENTS.PRIMARY],
            },
            body: {
                fontSize: 12,
                lineHeight: "18px",
            },
        },
        MuiTableSortLabel: {
            active: {
                color: fontColors[THEME_ELEMENTS.PRIMARY],
            },
        },
        MuiDialog: {
            container: {
                alignItems: "baseline",
            },
        },
        MuiTooltip: {
            tooltip: {
                fontSize: 12,
                lineHeight: "18px",
            },
        },
        MuiInput: {
            root: {
                backgroundColor: white,
            },
        },
        MuiOutlinedInput: {
            notchedOutline: {
                borderColor: "inherit !important",
            },
            root: {
                borderColor: green,
                "&$focused": {
                    borderColor: green,
                },
                "&$error": {
                    borderColor: red,
                },
            },
            input: {
                padding: "11px 20px",
            },
        },
        MuiButton: {
            root: {
                padding: "padding: 12px 30px",
            },
        },
    },
});
