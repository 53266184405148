import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { SIcon } from "_Components/Icons/SIcon";
import { ICONS } from "_Components/Icons/SIconPaths";
import {
    BrowserListItem,
    BROWSERS,
} from "_Components/Layout/SNotSupported/_Components/BrowserListItem";
import { borderBig } from "_Styles/borderRadius";
import {
    colors,
    textButtonBoxShadow,
    transparentBlue,
    white,
} from "_Styles/colors";
import { defaultFontSize, titleFontSize } from "_Styles/fontSizes";
import { THEME_ELEMENTS } from "_Styles/styling.types";
import React, { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

const InfoPage = styled.div`
    background-color: ${transparentBlue};
    width: 100vw;
    height: 100vh;
`;

const StyledWindow = styled.div`
    width: 80vw;
    height: 90vh;
    margin: 5vh auto;
    background-color: ${white};
    border-radius: ${borderBig};
    box-shadow: ${textButtonBoxShadow};
    text-align: center;
`;

const StyledLogo = styled.div`
    padding: 5rem 0;
`;

const IconStyleSvg = css`
    width: 3.3rem;
    height: 4rem;
    z-index: 1;
    fill: ${colors[THEME_ELEMENTS.PRIMARY]};
    margin-top: 3.3rem;
    transform: scale(1.2);
`;

const StyledTitle = styled.div`
    font-size: ${titleFontSize};
    margin-bottom: 5rem;
`;
const StyledText = styled.div`
    font-size: ${defaultFontSize};
    line-height: ${titleFontSize};
    width: 30vw;
    margin: auto;
`;

const BrowserList = styled.div`
    width: 100vw;
    margin-left: -10vw;
    margin-top: 10vh;
    min-height: 25rem;
    box-shadow: ${textButtonBoxShadow};
    background-color: ${white};
`;

export const SNotSupported: FunctionComponent = () => {
    const { t } = useTranslation();
    return (
        <InfoPage>
            <StyledWindow>
                <StyledLogo>
                    <SIcon
                        type={ICONS.LOGO}
                        svgSerializedStyle={IconStyleSvg}
                    />
                </StyledLogo>
                <StyledTitle>{t("NotSupported.title")}</StyledTitle>
                <StyledText>{t("NotSupported.text")}</StyledText>
                <BrowserList>
                    <BrowserListItem browser={BROWSERS.CHROME} />
                    <BrowserListItem browser={BROWSERS.FIREFOX} />
                    <BrowserListItem browser={BROWSERS.SAFARI} />
                    <BrowserListItem browser={BROWSERS.OPERA} />
                </BrowserList>
            </StyledWindow>
        </InfoPage>
    );
};
