import { IPromiseBasedObservable } from "mobx-utils";
import React, { useContext, useEffect, useState } from "react";
import { createContext } from "react";

export interface IFetchingContext {
    isFetching: boolean;
    isError: boolean;
    passPromisees?: (promises: IPromiseBasedObservable<any>[]) => void;
}

const initialContext = {
    isFetching: false,
    isError: false,
};

export const FetchingContext = createContext<IFetchingContext>(initialContext);

export function useFetchingContext() {
    return useContext(FetchingContext);
}

export const FetchingStateProvider = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    const [isFetching, setIsFetching] = useState(false);
    const [isError, setIsError] = useState(false);
    const observedPromises: Set<IPromiseBasedObservable<any>> = new Set();

    const passPromisees = (promises: IPromiseBasedObservable<any>[]) => {
        promises.forEach(promise => observedPromises.add(promise));
        checkPromisesState();
    };

    const checkPromisesState = () => {
        observedPromises.forEach(promise => {
            promise.case({
                pending: () => setIsFetching(true),
            });
        });
    };

    useEffect(() => {
        if (isFetching) {
            Promise.all(observedPromises)
                .then(() => {
                    setIsFetching(false);
                    setIsError(false);
                    observedPromises.clear();
                })
                .catch(error => {
                    setIsFetching(false);
                    setIsError(true);
                    return error;
                });
        }
    }, [isFetching]);

    return (
        <FetchingContext.Provider
            value={{ isFetching, passPromisees, isError }}
        >
            {children}
        </FetchingContext.Provider>
    );
};
