import styled from "@emotion/styled";
import {
    LOADER_STATUS,
    LoaderSpinner,
} from "_Components/LoaderCurtain/_Components/LoaderSpinner";
import React, { FunctionComponent } from "react";
import { useFetchingContext } from "_Context/FetchingStateContext/FetchingStateContext";

const Curtain = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: calc(100vh);
    width: calc(100vw);

    z-index: 9999;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: rgba(36, 104, 227, 0.19);
`;

export const SLoaderCurtain: FunctionComponent = () => {
    const { isFetching, isError } = useFetchingContext();
    const loaderStatus = isFetching
        ? LOADER_STATUS.PENDING
        : isError
        ? LOADER_STATUS.FAILED
        : LOADER_STATUS.SUCCESS;

    return isFetching ? (
        <Curtain>
            <LoaderSpinner status={loaderStatus} />
        </Curtain>
    ) : null;
};
